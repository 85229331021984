import { ref } from 'vue';

const loading = ref(false);

function startLoading() {
  if (loading.value) return
  loading.value = true;
}

function stopLoading() {
  loading.value = false;
}

export default {
  loading,
  startLoading,
  stopLoading
};
