/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
// import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Composables
import { createVuetify, type ThemeDefinition } from 'vuetify'

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides

//Custom themes
const darkCustom: ThemeDefinition = {
  dark: true,
  colors: {
    background: '#010221',
    surface: '#0e103d',
    primary: '#FCFFF3',
    'primary-darken-1': '#C7CAC0',
    secondary: '#F92E2B',
    'secondary-darken-1': '#b20100',
    error: '#b20100',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
    'on-background': '#FCFFF3',
    footer: '#00213D',
    white: '#FCFFF3',
    black: '#010221',
    grey: '#B3B6BF',
  },
}

const lightCustom: ThemeDefinition = {
  dark: false,
  colors: {
    background: '#FCFFF3',
    surface: '#FCFFF3',
    primary: '#010221',
    'primary-darken-1': '#000000',
    secondary: '#F92E2B',
    'secondary-darken-1': '#b20100',
    error: '#b20100',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
    'on-background': '#010221',
    footer: '#E5E5E5',
    white: '#FCFFF3',
    black: '#010221',
    grey: '#81848B',
  },
}

export default createVuetify({
  theme: {
    defaultTheme: localStorage.theme || 'darkCustom',
    themes: {
      darkCustom,
      lightCustom
    },
  },
})
