import { Requester } from '../requester';
import { actualWorkspace } from '@/store/workspaces';

const requester = new Requester('/workspaces');

export class Workspace {
    static async getWorkspacesList() {
        return await requester.get();
    }

    static async updateWorkspace(data: Object) {
        await requester.patch(data)
    }

    static async getUserPermissions() {
        const authRequester = new Requester('/workspaces/permissions');
        const temp: any = {
            "workspace_id": actualWorkspace.value['_id']
        }
        const params = new URLSearchParams(temp).toString();
        return await authRequester.get(params)
    }

    static async getMembersInWorkspace(data: object) {
        const membersRequester = new Requester('/workspaces/members');
        const temp: any = {
            "workspace_id": actualWorkspace.value['_id'],
            ...data
        }
        const params = new URLSearchParams(temp).toString();
        return await membersRequester.get(params)
    }

    static async inviteMemberInWorkspace(email: string) {
        const membersRequester = new Requester('/workspaces/members');
        const temp: any = {
            "workspace_id": actualWorkspace.value['_id'],
            "email": email
        }
        return await membersRequester.post(temp)
    }

    static async sendInviteMemberInWorkspace(email: string) {
        const membersRequester = new Requester('/workspaces/members/invitations');
        const temp: any = {
            "workspace_id": actualWorkspace.value['_id'],
            "email": email
        }
        return await membersRequester.post(temp)
    }

    static async updateMemberInWorkspace(data: object) {
        const membersRequester = new Requester('/workspaces/members');
        const temp: any = {
            "workspace_id": actualWorkspace.value['_id'],
            ...data
        }
        return await membersRequester.patch(temp)
    }

    static async deleteMemberInWorkspace(memberId: string) {
        const membersRequester = new Requester('/workspaces/members');
        const temp: any = {
            "workspace_id": actualWorkspace.value['_id'],
            "member_id": memberId
        }
        return await membersRequester.delete(temp)
    }

    static async acceptWorkspaceInvitation(token: string) {
        const invitRequester = new Requester('/workspaces/members/join')
        return await invitRequester.post({ "token": token })
    }

    static async getWorkspaceSubscription() {
        const authRequester = new Requester('/workspaces/subscriptions');
        const temp: any = {
            "workspace_id": actualWorkspace.value['_id']
        }
        const params = new URLSearchParams(temp).toString();
        return await authRequester.get(params)
    }

    static async getWorkspacePlans() {
        const authRequester = new Requester('/workspaces/plans');
        const temp: any = {
            "workspace_id": actualWorkspace.value['_id']
        }
        const params = new URLSearchParams(temp).toString();
        return await authRequester.get(params)
    }

    static async updatePlansInWorkspace(data: object) {
        const plansRequester = new Requester('/workspaces/plans');
        const temp: any = {
            "workspace_id": actualWorkspace.value['_id'],
            ...data
        }
        return await plansRequester.patch(temp)
    }

    static async cancelWorkspace() {
        const stripeRequester = new Requester('/workspaces/subscriptions');
        const temp: any = {
            "workspace_id": actualWorkspace.value['_id']
        }
        return await stripeRequester.delete(temp)
    }

    static async getIdPlayerRoblox(data: object) {
        const stripeRequester = new Requester('/workspaces/playeridroblox');
        const temp: any = {
            ...data
        }
        
        return await stripeRequester.get(temp)
    }
}

