import { AxiosResponse, AxiosError } from 'axios';
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';
import { useAppStore } from '../store/app';

export function handleSuccess(response: AxiosResponse<any>): void {
    if (!response) return;
    if (response.config.method === 'get') return;

    const appStore = useAppStore();
    const { success_snack, snack_text } = storeToRefs(appStore);

    success_snack.value = true;
    if (response.data) {
        if (typeof(response.data) == 'string') snack_text.value = response.data;
        if (typeof(response.data) == 'object') snack_text.value = response.data.message;
        return;
    }

    snack_text.value = 'Action has been completed successfully';
}

export function handleError(response: AxiosError<any>): void {
    if (!response) return;

    const router = useRouter();
    const appStore = useAppStore();
    const { error_snack, snack_text } = storeToRefs(appStore);

    error_snack.value = true;
    if (response.response) {
        if (typeof(response.response) == 'string') snack_text.value = response.response;
        if (typeof(response.response) == 'object') snack_text.value = response.response.data;
    } else {
        snack_text.value = response.message;
    }

    if (response.status == 401) {
        localStorage.clear();
        if (router.currentRoute.value.name) localStorage.setItem('next', router.currentRoute.value.name.toString())
        router.push({ name : 'login' });
    } 
}