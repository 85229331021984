<template>
  <v-app>
    <v-main>
      <v-progress-linear
        indeterminate
        v-if="loading"
        style="z-index: 10000; position: fixed;"
        color="primary"
      ></v-progress-linear>
      <v-snackbar v-model="success_snack" timeout="2000" color="green" class="snackbar" variant="tonal">{{ snack_text }}</v-snackbar>
      <v-snackbar v-model="error_snack" timeout="2000" color="red" class="snackbar" variant="tonal">{{ snack_text }}</v-snackbar>
      <router-view />
    </v-main>
  </v-app>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useAppStore } from './store/app';
import loadingStore from '@/store/loading';

const { loading } = loadingStore;
const appStore = useAppStore();
let { success_snack, error_snack, snack_text } = storeToRefs(appStore);
</script>

<style>
  body {
    overflow: hidden;
  }
</style>
