import { Requester } from '../requester';
import { actualWorkspace } from '@/store/workspaces';

export class Stripe {
    static async updatePlansInWorkspace(data: object) {
        const subscriptionsRequester = new Requester('/stripe/subscriptions');
        const temp: any = {
            "workspace_id": actualWorkspace.value['_id'],
            ...data
        }
        return await subscriptionsRequester.post(temp)
    }
}

