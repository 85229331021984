import { Requester } from '../requester';

const requester = new Requester('/users');

export class Users {
    
    static async getUser() {
        return await requester.get();
    }

    static async updateUser(data: object) {
        return await requester.patch(data);
    }

    static async updatePrimaryWorkspace(data: object) {
        const wkRequester = new Requester('/users', false);
        return await wkRequester.patch(data);
    }

    static async updateUserPassword(oldPassword: string, password: string) {
        const reqPassword = new Requester('/users/password');
        await reqPassword.post({
            'old_password': oldPassword,
            'new_password': password
        });
    }

    static async deleteUser() {
        await requester.delete();
    }

    static async logoutUser() {
        const logoutRequester = new Requester('/users/logout');
        await logoutRequester.post();
    }

    static async sendCode() {
        const CodeRequester = new Requester('/users/update-verification')
        await CodeRequester.post({
            'userId': localStorage.getItem('userId')
        });
    }
}