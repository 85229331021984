/**
 * main.ts
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

// Plugins
import { registerPlugins } from '@/plugins'

// Icons
import { OhVueIcon, addIcons } from "oh-vue-icons";
import { 
    BiSunFill, 
    BiMoonFill, 
    MdSpacedashboardRound, 
    FaBan, 
    BiPlus, 
    MdDashboardRound, 
    BiTriangleFill, 
    BiChevronCompactLeft, 
    MdHomeRound, 
    FaShoppingBasket, 
    MdReviews,
    HiSolidMail,
    RiLockPasswordFill,
    FaUserAlt,
    MdAccountcircle,
    BiTelephoneFill,
    FaMapMarkedAlt,
    HiSolidOfficeBuilding,
    BiEyeFill,
    BiEyeSlashFill,
    MdGavel,
    MdManageaccounts,
    IoGameController,
    FaServer,
    MdBoltSharp,
    HiSolidCode,
    FaCog,
    MdLogout,
    HiSolidPlus,
    SiDiscord,
    GiWorld,
    LaLinkedinIn,
    BiStarFill,
    BiStar,
    BiCheckLg, 
    MdClose, 
    BiArrowDown, 
    BiArrowUp,
    BiArrowLeft,
    BiArrowRight,
    FaFileInvoice,
    MdMenu,
    MdCheckbox,
    BiChevronDown,
    BiChevronUp,
    BiChevronLeft,
    BiChevronRight,
    BiChevronBarLeft,
    BiChevronBarRight,
    MdCheckboxoutlineblank,
    BiTrashFill,
    BiBook,
    BiDownload,
    FaCrown,
    BiLightningChargeFill,
} from "oh-vue-icons/icons";

addIcons(
    BiSunFill, 
    BiMoonFill, 
    MdSpacedashboardRound, 
    FaBan, 
    BiPlus, 
    MdDashboardRound, 
    BiTriangleFill, 
    BiChevronCompactLeft, 
    MdHomeRound, 
    FaShoppingBasket, 
    MdReviews,
    HiSolidMail,
    RiLockPasswordFill,
    FaUserAlt,
    MdAccountcircle,
    BiTelephoneFill,
    FaMapMarkedAlt,
    HiSolidOfficeBuilding,
    BiEyeFill,
    BiEyeSlashFill,
    MdGavel,
    MdManageaccounts,
    IoGameController,
    FaServer,
    MdBoltSharp,
    HiSolidCode,
    FaCog,
    MdLogout,
    HiSolidPlus,
    SiDiscord,
    GiWorld,
    LaLinkedinIn,
    BiStarFill,
    BiStar,
    BiCheckLg, 
    MdClose, 
    BiArrowDown, 
    BiArrowUp,
    BiArrowLeft,
    BiArrowRight,
    FaFileInvoice,
    MdMenu,
    MdCheckbox,
    BiChevronDown,
    BiChevronUp,
    BiChevronLeft,
    BiChevronRight,
    BiChevronBarLeft,
    BiChevronBarRight,
    MdCheckboxoutlineblank,
    BiTrashFill,
    BiBook,
    BiDownload,
    FaCrown,
    BiLightningChargeFill,
    {
      name: 'twitter-x',
      width: 300,
      height: 271,
      d: 'm236 0h46l-101 115 118 156h-92.6l-72.5-94.8-83 94.8h-46l107-123-113-148h94.9l65.5 86.6zm-16.1 244h25.5l-165-218h-27.4z'
    }
);

// Components
import App from './App.vue'

import axios from 'axios';

axios.defaults.baseURL = import.meta.env.VITE_BACK_BASE_URL || 'https://api.jaily.net';

// Composables
import { createApp } from 'vue'

const app = createApp(App)

registerPlugins(app)


app.component("oh-vue-icon", OhVueIcon);
app.mount('#app')